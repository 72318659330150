import { IconMessage, typographyBody1, typographyCaption } from "cdk-radial";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { Typewriter } from "../Typewriter";

const StyledResponseTextContainer = styled.div`
  ${({ theme }) => typographyBody1(theme)}
  border: 1px solid ${({ theme }) => theme.color.gray[300].value};
  border-radius: 0 ${({ theme }) => theme.size.borderRadius.large.value};
  ${({ theme }) => theme.size.borderRadius.large.value}
  ${({ theme }) => theme.size.borderRadius.large.value};
  padding: 8px;
  margin-bottom: 16px;
  width: calc(100% - 64px);
`;

const StyledRequestOuterContainer = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: row;
`;

const StyledInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 8px;
`;

const StyledInfoText = styled.span`
  ${({ theme }) => typographyCaption(theme)}
`;

const StyledResponseIcon = styled(IconMessage)`
  fill: white;
  background-color: transparent;
`;

const StyledIconContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.color.gray[400].value};
  border-radius: 50%;
  height: 40px;
  justify-content: center;
  margin-top: 2px;
  width: 40px;
`;

const StyledNodeContainer = styled.div`
  margin-bottom: 16px;
  padding: 0;
  width: calc(100% - 80px);
  margin-left: 48px;
`;
export const ChatResponse = ({ chat, currentTime, onTypingChange }) => {
  const [showNode, setShowNode] = useState(false);
  const responseEndRef = useRef();

  const scrollToBottom = () => {
    if (responseEndRef.current) {
      responseEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  // scrolls to bottom of the chat log whenever a Node appears
  useEffect(() => {
    scrollToBottom();
  }, [showNode]);

  // Set a timer to show StyledNode after 3-4 seconds
  useEffect(() => {
    if (chat.node) {
      const timer = setTimeout(() => setShowNode(true), 1500);
      return () => clearTimeout(timer);
    }
  }, [chat.node]);

  return (
    <>
      <StyledRequestOuterContainer>
        <StyledIconContainer>
          <StyledResponseIcon />
        </StyledIconContainer>
        <StyledResponseTextContainer>
          <StyledInfoContainer>
            <StyledInfoText>AIVA</StyledInfoText>
            <StyledInfoText>{currentTime}</StyledInfoText>
          </StyledInfoContainer>
          <Typewriter
            minDelay={50}
            maxDelay={200}
            text={chat.text}
            onTypingChange={onTypingChange}
          />
        </StyledResponseTextContainer>
      </StyledRequestOuterContainer>
      {showNode && chat.node && (
        <StyledNodeContainer>{chat.node}</StyledNodeContainer>
      )}
      <div ref={responseEndRef} />
    </>
  );
};

export const expressions = [
  "I apologize, but I'm having trouble grasping what you're asking.",
  "I'm sorry, but I'm not quite following what you mean.",
  "Forgive me, but I'm struggling to comprehend your question.",
  "My apologies, but I'm having difficulty understanding your inquiry.",
  "I'm sorry, I'm just not getting what you're asking.",
  "I apologize, but I'm having trouble wrapping my head around your question.",
  "Forgive me, but I'm having a hard time understanding the gist of your question.",
  "I'm sorry, could you please clarify your question? I'm having trouble understanding.",
  "My apologies, but I'm not quite sure I understand what you're asking.",
  "I'm sorry, could you rephrase your question? I'm having difficulty understanding it.",
];
