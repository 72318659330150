import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import parse from "html-react-parser";

/**
 * Simulates the effect of typing out a string word by word.
 * The delay between each word is randomized between a minimum and maximum value.
 *
 * @component
 * @param {Object} props - Component props.
 * @param {string} props.text - The text to be typed out.
 * @param {number} [props.minDelay=500] - The minimum delay (in milliseconds) between typing each word.
 * @param {number} [props.maxDelay=1000] - The maximum delay (in milliseconds) between typing each word.
 * @returns {React.ReactElement} The Typewriter component.
 */

export const Typewriter = styled(
  ({ className, text, minDelay = 500, maxDelay = 1000, onTypingChange }) => {
    const typingEndRef = useRef();
    const [words, setWords] = useState([]);
    const [displayedText, setDisplayedText] = useState([]);
    const [isTyping, setIsTyping] = useState(false);
    const [nextWordIndex, setNextWordIndex] = useState(0);

    useEffect(() => {
      const wordsArray = splitTextIntoWords(text);
      setWords(wordsArray);
    }, [text]);

    const splitTextIntoWords = (text) => {
      const elements = parse(text);
      const words = [];
      if (Array.isArray(elements)) {
        elements.forEach((element) => {
          if (typeof element === "string") {
            const elementWords = element.split(/\s+/);
            words.push(...elementWords);
          } else if (element.type === "b") {
            const boldWords = element.props.children.split(/\s+/);
            if (boldWords.length > 0) {
              for (let i = 0; i < boldWords.length; i++) {
                if (i < boldWords.length - 1) {
                  words.push(<strong>{boldWords[i] + " "}</strong>);
                } else {
                  words.push(<strong>{boldWords[i]}</strong>);
                }
              }
            }
          } else if (element.type === "i") {
            const italicWords = element.props.children.split(/\s+/);
            if (italicWords.length > 0) {
              for (let i = 0; i < italicWords.length; i++) {
                if (i < italicWords.length - 1) {
                  words.push(<i>{italicWords[i] + " "}</i>);
                } else {
                  words.push(<i>{italicWords[i]}</i>);
                }
              }
            }
          } else {
            words.push(element);
          }
        });
      } else {
        const arrayOfWords = elements.split(" ");
        words.push(...arrayOfWords);
      }
      return words;
    };

    const scrollToBottom = () => {
      if (typingEndRef.current) {
        typingEndRef.current.scrollIntoView({ behavior: "smooth" });
      }
    };

    // scrolls to bottom of the chat log whenever it changes
    useEffect(() => {
      scrollToBottom();
    }, [isTyping]);

    useEffect(() => {
      if (nextWordIndex < words.length) {
        setIsTyping(true);
        const timeoutId = setTimeout(() => {
          setDisplayedText((prevText) => {
            // Only remove the cursor when adding new words.
            const nextText = [...prevText, words[nextWordIndex]];
            return nextText;
          });
          setNextWordIndex(nextWordIndex + 1);
        }, getRandomDelay(minDelay, maxDelay));

        return () => clearTimeout(timeoutId);
      } else {
        // Remove the cursor block only after the entire text has been displayed.
        setIsTyping(false);
      }
    }, [nextWordIndex, words, minDelay, maxDelay]);

    useEffect(() => {
      onTypingChange(isTyping);
    }, [isTyping, onTypingChange]);

    return (
      <span className={`Typewriter ${className} ${isTyping ? "typing" : ""}`}>
        {displayedText.map((word, index) => (
          <React.Fragment key={index}>
            {typeof word === "string" ? word + " " : word}
          </React.Fragment>
        ))}
        <svg
          className={`Dot ${className} ${isTyping ? "typing" : ""}`}
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
        >
          <circle id="dot" cx="9" cy="9" r="6" fill="lightgray">
            <animate
              attributeName="r"
              values="5;6;5"
              dur="1s"
              repeatCount="indefinite"
            />
          </circle>
        </svg>
        <div ref={typingEndRef} />
      </span>
    );
  }
)`
  position: relative;
  &.Dot {
    display: none;
    margin-bottom: -6px;
    margin-left: -2px;
  }
  &.Dot.typing {
    display: inline-block;
  }
`;

const getRandomDelay = (minDelay, maxDelay) => {
  return Math.floor(Math.random() * (maxDelay - minDelay + 1) + minDelay);
};
