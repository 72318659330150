import {
  AvatarWrapper,
  Heading,
  HEADING_LEVELS,
  HEADING_TYPES,
  typographyBody1,
  typographyCaption,
} from "cdk-radial";
import styled from "styled-components";

const StyledRequestOuterContainer = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: row;
  justify-content: flex-end;
`;

const StyledRequestTextContainer = styled.div`
  ${({ theme }) => typographyBody1(theme)}
  background-color: ${({ theme }) => theme.color.primary[50].value};
  border: 1px solid ${({ theme }) => theme.color.primary[100].value};
  border-radius: ${({ theme }) => theme.size.borderRadius.medium.value} 0
    ${({ theme }) => theme.size.borderRadius.medium.value}
    ${({ theme }) => theme.size.borderRadius.medium.value};
  padding: 8px;
  margin-bottom: 16px;
  width: calc(100% - 64px);
`;

const StyledInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 8px;
`;

const StyledInfoText = styled.span`
  ${({ theme }) => typographyCaption(theme)}
`;

const StyledAvatarWrapper = styled(AvatarWrapper)`
  background-color: ${({ theme }) => theme.color.primary[400].value};
`;

const getInitials = (name) => {
  const words = name.split(" ");
  let firstLetters = "";

  words.forEach((word) => {
    if (word.length > 0) {
      firstLetters += word[0];
    }
  });
  return firstLetters;
};

export const UserRequest = ({ currentTime, text, userName }) => {
  return (
    <StyledRequestOuterContainer>
      <StyledRequestTextContainer>
        <StyledInfoContainer>
          <StyledInfoText>{userName}</StyledInfoText>
          <StyledInfoText>{currentTime}</StyledInfoText>
        </StyledInfoContainer>
        {text}
      </StyledRequestTextContainer>
      <StyledAvatarWrapper>
        <Heading
          level={HEADING_LEVELS.LEVEL_3}
          type={HEADING_TYPES.SUBTITLE_1}
          style={{ color: "white" }}
        >
          {getInitials(userName)}
        </Heading>
      </StyledAvatarWrapper>
    </StyledRequestOuterContainer>
  );
};
