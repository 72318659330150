import { useLayoutEffect, useState } from "react";

export const DEVICE = {
  IPAD_PRO_M4_13: {
    id: "IPAD_PRO_M4_13",
    width: 1032,
    height: 1376,
  },
  IPAD_PRO_M4_11: {
    id: "IPAD_PRO_M4_11",
    width: 834,
    height: 1210,
  },
  IPAD_AIR_M2_13: {
    id: "IPAD_AIR_M2_13",
    width: 1024,
    height: 1366,
  },
  IPAD_AIR_M2_11: {
    id: "IPAD_AIR_M2_11",
    width: 820,
    height: 1180,
  },
  IPAD_MINI_6: {
    id: "IPAD_MINI_6",
    width: 744,
    height: 1133,
  },
  IPAD_PRO_M2_11: {
    id: "IPAD_PRO_M2_11",
    width: 834,
    height: 1194,
  },
  /*
   * Same as IPAD_AIR_M2_11
  IPAD_10: {
    LANDSCAPE: { width: 1180, height: 746 },
    PORTRAIT: { width: 820, height: 1106 },
  },
   * Same as IPAD_AIR_M2_13
  IPAD_PRO_M2_13: {
    LANDSCAPE: { width: 1366, height: 950 },
    PORTRAIT: { width: 1024, height: 1292 },
  },
  */
};

export const ORIENTATION = {
  LANDSCAPE: "landscape",
  PORTRAIT: "portrait",
};

export const useOnDevice = () => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [device, setDevice] = useState(undefined);
  const [orientation, setOrientation] = useState(undefined);
  const [screenOrientation, setScreenOrientation] = useState(undefined);

  useLayoutEffect(() => {
    const updateDimensions = () => {
      const maxWidth = window.screen.width;
      const maxHeight = window.screen.height;

      setWidth(maxWidth);
      setHeight(maxHeight);
      if (window.screen.orientation.type.includes("portrait"))
        setOrientation(ORIENTATION.PORTRAIT);
      if (window.screen.orientation.type.includes("landscape"))
        setOrientation(ORIENTATION.LANDSCAPE);

      Object.entries(DEVICE).forEach(([key, device]) => {
        if (device.width === maxWidth && device.height === maxHeight) {
          setDevice(key);
        }
      });
    };
    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  return [
    { device, orientation, screenOrientation },
    { width, height },
  ];
};
