import { Fab, KEY_VALUES } from "cdk-radial";
import { useEffect, useState } from "react";
import { ChatContainer } from "./ChatContainer";
import { AiIcon } from "../utilities";
/* eslint-disable react-hooks/exhaustive-deps */

import {
  collapseAnimation,
  expandAnimation,
  getCurrentTime,
} from "../utilities";

import styled from "styled-components";

const ChatSizingContainer = styled.div`
  position: fixed;
  border-radius: ${({ theme }) => theme.size.borderRadius.large.value};
  bottom: 0px;
  right: 0px;
  width: ${({ $isNavVisible, $size }) => {
    if ($isNavVisible && $size === "large") {
      return "calc(100% - 256px)";
    }
    if ($isNavVisible && $size === "small") {
      return "calc(50% + 40px)";
    }
    if (!$isNavVisible && $size === "small") {
      return "50%";
    }
    return "100%";
  }};
  &.initial {
    display: none;
  }
  &.expanded {
    animation: ${expandAnimation} 0.25s forwards;
    animation-timing-function: ease-in-out;
    animation-fill-mode: both;
  }
  &.collapsed {
    animation: ${collapseAnimation} 0.25s forwards;
    animation-timing-function: ease-in-out;
    animation-fill-mode: both;
  }
  @media (max-width: 768px) {
    /* Media query for screens smaller than 768px (typical tablet and mobile sizes) */
    width: 100%;
  }
`;

const FabContainer = styled.div`
  position: fixed;
  bottom: 32px;
  right: 32px;
  z-index: ${({ theme }) => theme.zIndex.fab.value};
`;

const StyledFab = styled(Fab)`
  display: ${({ $isOpen }) => ($isOpen ? "none" : "flex")};
  z-index: ${({ theme }) => theme.zIndex.fab.value};
`;

export const Chat = ({
  chatLabel = "",
  chatLog,
  chatRequest,
  headerText,
  itemToAppend,
  isChatOpen,
  isNavVisible,
  onRequestChange,
  onResponseChange,
  handleChatOpen,
  placeholder = "Write a message...",
  setChatLog,
  userName,
}) => {
  const [animationClass, setAnimationClass] = useState("initial");
  const [chatSize, setChatSize] = useState("large");
  const [hasMounted, setHasMounted] = useState(false);

  const handleChatLog = (event, isSendButtonClick = false) => {
    if (isChatOpen && event.key === KEY_VALUES.ENTER) {
      event.preventDefault();
      appendChatLog(event);
    }
    if (isSendButtonClick) {
      appendChatLog(event);
    }
  };

  const appendChatLog = (event) => {
    const response = onResponseChange(event); // comes from app
    if (!response.text) return;

    const latestResponse = {
      currentTime: getCurrentTime(),
      getMore: response.getMore,
      node: response.node,
      text: response.text,
      type: "response",
    };

    setChatLog([
      ...chatLog,
      {
        currentTime: getCurrentTime(),
        type: "request",
        text: event.target.value.toString(),
      },
      latestResponse,
    ]);
    onRequestChange({ target: { value: "" } });
  };

  const handleOpenChat = () => {
    handleChatOpen(true);
  };

  const handleCloseChat = () => {
    handleChatOpen(false);
  };

  const handleChatResize = (size) => {
    setChatSize(size);
  };

  // this is the 7 second delay on the response getMore property
  useEffect(() => {
    if (chatLog.length > 0 && chatLog[chatLog.length - 1].getMore?.text) {
      const newRequest = chatLog[chatLog.length - 1].getMore;
      setTimeout(() => {
        setChatLog([
          ...chatLog,
          {
            currentTime: getCurrentTime(),
            node: newRequest.node,
            text: newRequest.text,
            type: "response",
          },
        ]);
      }, 7000);
    }
  }, [chatLog, setChatLog]);

  useEffect(() => {
    if (itemToAppend && itemToAppend[0]?.text) {
      setChatLog([...chatLog, ...itemToAppend]);
    }
  }, [itemToAppend]);

  useEffect(() => {
    if (hasMounted) {
      setAnimationClass(isChatOpen ? "expanded" : "collapsed");
    } else {
      setHasMounted(true);
    }
  }, [isChatOpen]);

  return (
    <>
      <FabContainer>
        <StyledFab
          text={headerText}
          icon={<AiIcon />}
          isCollapsible
          onClick={handleOpenChat}
          $isOpen={isChatOpen}
        />
      </FabContainer>
      <ChatSizingContainer
        className={`${animationClass} ChatSizingContainer`}
        $isNavVisible={isNavVisible}
        $size={chatSize}
      >
        <ChatContainer
          chatLog={chatLog}
          chatSize={chatSize}
          headerText={headerText}
          isOpen={isChatOpen}
          label={chatLabel}
          onChange={onRequestChange}
          onChatChange={handleChatLog}
          onChatClose={handleCloseChat}
          onChatResize={handleChatResize}
          placeholder={placeholder}
          request={chatRequest}
          userName={userName}
        />
      </ChatSizingContainer>
    </>
  );
};
