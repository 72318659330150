import { keyframes } from "styled-components";

export const expandAnimation = keyframes`
  0% {
    transform: scale(0);
    transform-origin: bottom right;
  }
  100% {
    transform: scale(1);
    transform-origin: bottom right;
  }
`;

export const collapseAnimation = keyframes`
  0% {
    transform: scale(1);
    transform-origin: bottom right;
  }
  100% {
    transform: scale(0);
    transform-origin: bottom right;
  }
`;
