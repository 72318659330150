export const BACKGROUND_OPTIONS = {
  UNIFY_HOME: {
    path: "/My-Unify-1440x976.png",
    width: "1440px",
    height: "976px",
  },
  IPAD_PRO_M4_13_LANDSCAPE: {
    path: "/Unify-Dashboard-1376x771-iPad-Pro-13.png",
    width: "1376px",
    height: "771px",
  },
  IPAD_PRO_M4_13_PORTRAIT: {
    path: "/Unify-Dashboard-1032x713-iPad-Pro-13.png",
    width: "1032px",
    height: "713px",
  },
  IPAD_AIR_M2_13_LANDSCAPE: {
    path: "/Unify-Dashboard-1366x713-iPad-Air-13.png",
    width: "1366px",
    height: "713px",
  },
  IPAD_AIR_M2_13_PORTRAIT: {
    path: "/Unify-Dashboard-1024x713-iPad-Air-13.png",
    width: "1024px",
    height: "713px",
  },
  INTELLIGENCE_SUITE: {
    path: "/Variable-Operations-Overview-1650x976.png",
    width: "1650px",
    height: "976px",
  },
  ASR_REPORT_IPAD_PRO_13_LANDSCAPE: {
    path: "/ASR-Performance-iPad-Pro-13-1376x1507.png",
    width: "1376px",
    height: "1507px",
  },
  ASR_REPORT_IPAD_PRO_13_PORTRAIT: {
    path: "/ASR-Performance-iPad-Pro-13-1032x1507.png",
    width: "1032px",
    height: "1507px",
  },
  ASR_REPORT_IPAD_AIR_13_LANDSCAPE: {
    path: "/ASR-Performance-iPad-Air-13-1366x1507.png",
    width: "1366px",
    height: "1507px",
  },
  ASR_REPORT_IPAD_AIR_13_PORTRAIT: {
    path: "/ASR-Performance-iPad-Air-13-1024x1507.png",
    width: "1024px",
    height: "1507px",
  },
  ASR_REPORT: {
    path: "/ASR-Performance-Reports-Collapsed-No-Header-1440x1507.png",
    width: "1440px",
    height: "1507px",
  },
};
