import { createIconWithPath } from "cdk-radial";

const IconPath = [
  {
    d: "M0 11H3V14H5V9H0V11ZM3 3H0V5H5V0H3V3ZM9 14H11V11H14V9H9V14ZM11 3V0H9V5H14V3H11Z",
    fillRule: "evenodd",
  },
];

export const DisplayMinimizeIcon = createIconWithPath(IconPath);
