import { createIconWithPath } from "cdk-radial";

const IconPath = [
  {
    d: "M2 9H0V14H5V12H2V9ZM0 5H2V2H5V0H0V5ZM12 12H9V14H14V9H12V12ZM9 0V2H12V5H14V0H9Z",
    fillRule: "evenodd",
  },
];

export const DisplayMaximizeIcon = createIconWithPath(IconPath);
