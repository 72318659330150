import { useEffect, useState } from "react";
import { Chip, Content, TextButton } from "cdk-radial";
import styled from "styled-components";
import "./App.css";
import { AppHeader } from "./AppHeader/AppHeader";
import { BACKGROUND_OPTIONS, Chat, expressions } from "./UnifyChatComponents";
import {
  getCurrentTime,
  ISTile,
  useOnDevice,
  DEVICE,
  ORIENTATION,
} from "./utilities";

const TempDimensions = styled.div`
  position: absolute;
  padding: 50px;
  font-size: 2em;
  ${({ $device }) => {
    console.log($device);
    if ($device?.device === DEVICE.IPAD_PRO_13) {
      return "background: red";
    }
  }}
  background: white;
  z-index: 100;
`;

const StyledAppContainer = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
`;

const NewApp = styled.div`
  /* border-bottom: 2px solid red; */
`;

const Comp = styled.div`
  position: relative;
  overflow-y: auto;
`;

const Img = styled.img`
  width: ${({ width }) => width};
  object-fit: cover;
  position: absolute;
  left: 0;
  top: 0;
`;

const StyledPromptContainer = styled.div`
  display: flex;
  gap: 16px;
`;

const StyledChip = styled(Chip)`
  button {
    height: auto;
    max-height: max-content;
    label {
      white-space: normal;
    }
  }
`;

const StyledButtonText = styled(Content)`
  padding-left: 12px;
  padding-top: 8px;
`;

const App = styled(({ className }) => {
  const userName = "Stanton Brooks";
  const firstName = "Stanton";
  const chatHeaderText = "CDK AIVA";
  const chatPlaceholder = "Write a message...";
  const chatLabel = "Message ChatCDK";

  const [isChatOpen, setIsChatOpen] = useState(false);
  const [isNavVisible, setIsNavVisible] = useState(false);
  const [chatRequest, setChatRequest] = useState("");
  const [chatLog, setChatLog] = useState([]);
  const [itemToAppend, setItemToAppend] = useState({});
  const [background, setBackground] = useState();
  const [page, setPage] = useState("Unify Home");

  const handleRequestChange = (event) => {
    setChatRequest(event.target.value);
  };

  // adding 'onlyRespond: ' to a getResponse keeps it from adding a request
  const handleISTileClick = () => {
    setBackground(BACKGROUND_OPTIONS.INTELLIGENCE_SUITE);
    setIsNavVisible(true);
    getResponse(
      {
        target: {
          value: "onlyRespond: You are in Intelligence Suite",
        },
      },
      true // note the 'true' here, as this is an internal getResponse call.
    );
  };

  const handleGoToASRDashboard = () => {
    setIsNavVisible(true); // TODO audit this for spacing
    getResponse(
      {
        target: {
          value: "onlyRespond: Here is your ASR Performance Dashboard",
        },
      },
      true // note the 'true' here, as this is an internal getResponse call.
    );
    setPage("ASR Dashboard");
  };

  /**
   * This function simulates getting a response from a request...
   * This will need to change to "plug in" to a REST call
   **/
  const getResponse = (event, isInternalCall) => {
    const randomIndex = Math.floor(Math.random() * expressions.length);

    const newResponse = {};

    // simulate a couple different responses
    const value = event.target.value.toLowerCase();

    if (value === "hello") {
      newResponse.text = "Well, hello there!";
    } else if (value === "hi") {
      newResponse.text = "Hi right back to ya!";
    }

    // ------------- Original Unify sales and new vehicles Use Cases -------------
    else if (value.includes("my reports")) {
      newResponse.text = `Hello ${firstName}, you can view them in Intelligence Suite.`;
      newResponse.node = (
        <ISTile
          color="#6a2875"
          label="Intelligence Suite"
          onTileClick={handleISTileClick}
          textInitials="IS"
        />
      );
    } else if (value.includes("new vehicles")) {
      newResponse.text =
        "The average financed monthly payment on new vehicles for the current month is <b>$849.87</b>. The highest financed monthly payment for the current month is <b>$1049.86</b>, and the lowest financed monthly payment for the current month is <b>$649.88</b>.";
      newResponse.node = (
        <TextButton
          text="View Report"
          onClick={() => console.log("View Report Button Clicked")}
        />
      );
      newResponse.getMore = {
        text: "Would you like to see the average financed monthly payment on new vehicles year-to-date in 2024, or another F&I Performance insight?",
        node: (
          <StyledPromptContainer>
            <StyledChip
              label="Average financed monthly payment in the previous month."
              onClick={() => console.log("Chip1 Clicked")}
            />
            <StyledChip
              label="Average financed monthly payment year-to-date in 2024."
              onClick={() =>
                getResponse(
                  {
                    target: {
                      value:
                        "View average financed monthly payment year-to-date in 2024.",
                    },
                  },
                  true // note the 'true' here, as this is an internal getResponse call.
                )
              }
            />
          </StyledPromptContainer>
        ),
      };
    } else if (
      value.includes(
        "view average financed monthly payment year-to-date in 2024"
      )
    ) {
      newResponse.text =
        "The average financed monthly payment YTD is <b>$987.39</b>. The highest financed monthly payment YTD is <b>$1187.40</b>, and the lowest financed monthly payment YTD is <b>$787.38</b>. Please let me know if there are any other F&I Performance insights you would like to see.";
      newResponse.node = (
        <TextButton
          text="View Report"
          onClick={() => console.log("View Report Button Clicked")}
        />
      );
    } else if (value.includes("you are in intelligence suite")) {
      newResponse.text =
        "You are in Intelligence Suite now where you can access all the reports related to your dealership to make better business decisions faster.";
    }

    // ------------- Fixed Ops Use Case 1 -------------
    else if (value.includes("what is my average sold")) {
      newResponse.text =
        "The total volume of repair orders for last 3 months was <b>16,380</b> with sold ASRs value representing <b>$1,085,665.32</b> vs. declined ASRs at <b>$2,545,059.00</b>. Your Sold ASRs were up 2% from previous qtr. and your declined ASRs were down 1%.";
      newResponse.node = (
        <StyledChip
          label="Take me to my ASR Performance Dashboard"
          onClick={handleGoToASRDashboard}
        />
      );
      newResponse.getMore = {
        text: "Would you like to see the average sold ASR $ amount per RO?",
        node: (
          <StyledPromptContainer>
            <StyledChip
              label="View average sold ASR $ amount for last 3 months"
              onClick={() => {
                getResponse(
                  {
                    target: {
                      value: "View average sold ASR $ amount for last 3 months",
                    },
                  },
                  true // note the 'true' here, as this is an internal getResponse call.
                );
              }}
            />
            <StyledChip label="View average sold ASR $ amount year-to-date in 2024" />
          </StyledPromptContainer>
        ),
      };
    } else if (
      value.includes("view average sold asr $ amount for last 3 months")
    ) {
      newResponse.text =
        "The avg. sold ASR $ amount per RO for last 3 months is <b>$370.28</b>.";
      newResponse.node = (
        <StyledChip
          label="Take me to my ASR Performance Dashboard"
          onClick={handleGoToASRDashboard}
        />
      );
    }
    // ------------- Fixed Ops Use Case 2 -------------
    else if (value.includes("what are my total actual sales")) {
      newResponse.text =
        "The total actual sales amount for last month is <b>$6,065,107.00</b>. Your total labor sales represented <b>$3,552,817.00</b> vs. parts sales of <b>$2,512,290.00</b>.";
      newResponse.node = (
        <StyledChip
          label="Take me to my ASR Performance Dashboard"
          onClick={handleGoToASRDashboard}
        />
      );
      newResponse.getMore = {
        text: "Would you like to see your effective labor rate (ELR)?",
        node: (
          <StyledPromptContainer>
            <StyledChip
              label="View effective labor rate for last month"
              onClick={() => {
                getResponse(
                  {
                    target: {
                      value: "View effective labor rate for last month",
                    },
                  },
                  true // note the 'true' here, as this is an internal getResponse call.
                );
              }}
            />
            <StyledChip label="View actual sale amounts year-to-date in 2024" />
          </StyledPromptContainer>
        ),
      };
    } else if (value.includes("view effective labor rate for last month")) {
      newResponse.text =
        "The effective labor rate for last month was <b>$183.24</b>, down 3% from the previous month.";
      newResponse.node = (
        <StyledChip
          label="Take me to my ASR Performance Dashboard"
          onClick={handleGoToASRDashboard}
        />
      );
    } else if (value.includes("here is your asr performance dashboard")) {
      newResponse.text = "You are now viewing your ASR Performance Dashboard.";
    } else {
      newResponse.text = expressions[randomIndex];
    }

    // This statement handles an internal call, bypassing the need for a user request block
    // and sets a state item accordingly
    if (isInternalCall) {
      if (event.target.value.includes("onlyRespond")) {
        console.log("only responding (or should be)");
        // using onlyRespond as a marker to only respond (no request)
        setItemToAppend([
          {
            currentTime: getCurrentTime(),
            node: newResponse.node,
            text: newResponse.text,
            type: "response",
          },
        ]);
      } else {
        setItemToAppend([
          {
            currentTime: getCurrentTime(),
            type: "request",
            text: event.target.value?.toString(),
          },
          {
            currentTime: getCurrentTime(),
            node: newResponse.node,
            text: newResponse.text,
            type: "response",
          },
        ]);
      }
    } else {
      return newResponse;
    }
  };

  const [viewer, size] = useOnDevice();

  useEffect(() => {
    document.addEventListener("DOMContentLoaded", (event) => {
      const chatBox = document.querySelector(".ChatSizingContainer");
      const chatInput = document.querySelector(".ChatInput");

      const adjustChatBox = () => {
        const chatInputRect = chatInput.getBoundingClientRect();
        const viewportHeight = window.innerHeight;
        const keyboardHeight = viewportHeight - chatInputRect.bottom;

        if (keyboardHeight > 0) {
          chatBox.style.bottom = `${keyboardHeight}px`;
        } else {
          chatBox.style.bottom = "0px";
        }
      };

      chatInput.addEventListener("focus", adjustChatBox);
      chatInput.addEventListener("blur", adjustChatBox);

      window.addEventListener("resize", adjustChatBox);
    });

    console.log("called UE", viewer);
    if (page === "Unify Home") {
      if (
        viewer.device === DEVICE.IPAD_PRO_M4_13.id &&
        viewer.orientation === ORIENTATION.LANDSCAPE
      ) {
        setBackground(BACKGROUND_OPTIONS.IPAD_PRO_M4_13_LANDSCAPE);
        return;
      }
      if (
        viewer.device === DEVICE.IPAD_PRO_M4_13.id &&
        viewer.orientation === ORIENTATION.PORTRAIT
      ) {
        setBackground(BACKGROUND_OPTIONS.IPAD_PRO_M4_13_PORTRAIT);
        return;
      }
      if (
        viewer.device === DEVICE.IPAD_AIR_M2_13.id &&
        viewer.orientation === ORIENTATION.LANDSCAPE
      ) {
        setBackground(BACKGROUND_OPTIONS.IPAD_AIR_M2_13_LANDSCAPE);
        return;
      }
      if (
        viewer.device === DEVICE.IPAD_AIR_M2_13.id &&
        viewer.orientation === ORIENTATION.PORTRAIT
      ) {
        setBackground(BACKGROUND_OPTIONS.IPAD_AIR_M2_13_PORTRAIT);
        return;
      }
      setBackground(BACKGROUND_OPTIONS.UNIFY_HOME);
    }
    if (page === "ASR Dashboard") {
      if (
        viewer.device === DEVICE.IPAD_PRO_M4_13.id &&
        viewer.orientation === ORIENTATION.LANDSCAPE
      ) {
        setBackground(BACKGROUND_OPTIONS.ASR_REPORT_IPAD_PRO_13_LANDSCAPE);
        return;
      }
      if (
        viewer.device === DEVICE.IPAD_PRO_M4_13.id &&
        viewer.orientation === ORIENTATION.PORTRAIT
      ) {
        setBackground(BACKGROUND_OPTIONS.ASR_REPORT_IPAD_PRO_13_PORTRAIT);
        return;
      }
      if (
        viewer.device === DEVICE.IPAD_AIR_M2_13.id &&
        viewer.orientation === ORIENTATION.LANDSCAPE
      ) {
        setBackground(BACKGROUND_OPTIONS.ASR_REPORT_IPAD_AIR_13_LANDSCAPE);
        return;
      }
      if (
        viewer.device === DEVICE.IPAD_AIR_M2_13.id &&
        viewer.orientation === ORIENTATION.PORTRAIT
      ) {
        setBackground(BACKGROUND_OPTIONS.ASR_REPORT_IPAD_AIR_13_PORTRAIT);
        return;
      }
      setBackground(BACKGROUND_OPTIONS.ASR_REPORT);
    }
  }, [page, viewer]);

  console.log("background path: ", background?.path);
  console.log("page", page);

  return (
    <StyledAppContainer className={className}>
      {/* <TempDimensions $device={viewer}>
        <div>
          device: '{size.width}' x '{size.height}'
        </div>
        <div>device: {viewer.device}</div>
        <div>oriented: {viewer.orientation}</div>
      </TempDimensions> */}
      <NewApp>
        <AppHeader
          onBackgroundChange={setBackground}
          onIsNavVisibleChange={setIsNavVisible}
          onChatVisibleChange={setIsChatOpen}
        />
      </NewApp>
      <Comp>
        <Img src={background?.path} width={background?.width} />
        <div>{background?.path}</div>
        <Chat
          chatLabel={chatLabel}
          chatLog={chatLog}
          chatRequest={chatRequest}
          headerText={chatHeaderText}
          handleChatOpen={setIsChatOpen}
          itemToAppend={itemToAppend}
          isChatOpen={isChatOpen}
          isNavVisible={isNavVisible}
          onRequestChange={handleRequestChange}
          onResponseChange={getResponse}
          placeholder={chatPlaceholder}
          setChatLog={setChatLog}
          userName={userName}
        />
      </Comp>
    </StyledAppContainer>
  );
})`
  width: 100%;
  height: 100dvh;
`;

export default App;
