import {
  CONTENT_TYPES,
  Content,
  IconArrowDropDown,
  IconButton,
  IconSend,
  Input,
  customScrollbar,
  typographySubtitle3,
} from "cdk-radial";
import { forwardRef, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ChatResponse } from "./ChatResponse";
import { UserRequest } from "./UserRequest";
import {
  AiIcon,
  DisplayMaximizeIcon,
  DisplayMinimizeIcon,
  MicIcon,
} from "../utilities";
/* eslint-disable react-hooks/exhaustive-deps */

// --- Containers: wrapper, header, scrolling chat container, text container for input
const StyledChatWrapper = styled.div.attrs({ className: "StyledChatWrapper" })`
  background-color: ${({ theme }) => theme.color.additional.light.value};
  border-radius: ${({ theme }) => theme.size.borderRadius.large.value};
  box-shadow: ${({ theme }) => theme.elevation[4].value};
  display: grid;
  grid-template-rows: 56px 1fr auto 16px;
  /* height: 100%; */
  width: 100%;
`;

const StyledChatHeader = styled.div.attrs({ className: "StyledChatHeadder" })`
  background-color: ${({ theme }) => theme.color.primary[500].value};
  border-radius: ${({ theme }) => theme.size.borderRadius.large.value}
    ${({ theme }) => theme.size.borderRadius.large.value} 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
`;

const StyledScrollingContainer = styled.div.attrs({
  className: "StyledScrollingContainer",
})`
  ${customScrollbar};
  border-bottom: 1px solid ${({ theme }) => theme.color.gray[300].value};
  overflow-y: auto;
  padding: 16px 16px 0 16px;
  max-height: calc(100vh - 256px);
`;

// --- Header components
const StyledHeaderContent = styled(Content)`
  display: flex;
  align-items: center;
  gap: 8px;

  span {
    ${({ theme }) => typographySubtitle3(theme)}
    color: ${({ theme }) => theme.color.additional.light.value};
  }
  svg {
    fill: ${({ theme }) => theme.color.additional.light.value};
    margin-top: 2px;
    margin-bottom: -2px;
  }
`;

const StyledHeaderIconButton = styled(IconButton)`
  && {
    && svg {
      fill: ${({ theme }) => theme.color.text.dark.secondary.value};
    }
  }
  &&:focus {
    background: ${({ theme }) => theme.color.primary[400].value};
    box-shadow: 0 0 0 4px ${({ theme }) => theme.color.primary[200].value};
  }
  &&:hover {
    background: ${({ theme }) => theme.color.primary[300].value};
  }
  &&:active {
    background: ${({ theme }) => theme.color.primary[600].value};
  }
`;

const StyledIconArrowDropdown = styled(IconArrowDropDown)`
  padding-bottom: 2px;
`;

const StyledDisplayMinimizeIcon = styled(DisplayMinimizeIcon)`
  padding-left: 10px;
  padding-top: 6px;
`;

const StyledDisplayMaximizeIcon = styled(DisplayMaximizeIcon)`
  padding-left: 10px;
  padding-top: 6px;
`;

// --- Chat components: request (with text from input) and response (with text from 'Ai')
const StyledRequestContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-top: 16px;
  position: relative;
  padding: 0 16px;
`;

// --- Input request container components
const InputWrapper = styled.div`
  width: 100%;
  label {
    padding-top: 0px;
  }
  span {
    display: none;
  }
`;

const StyledInputNoLabel = forwardRef(({ className, ...props }, ref) => (
  <InputWrapper className={className}>
    <Input ref={ref} {...props} />
  </InputWrapper>
));

const StyledBlueIconButton = styled(IconButton)`
  &&& svg {
    fill: ${({ theme }) => theme.color.primary[500].value};
  }
`;

const StyledMicrophoneIconButton = styled(IconButton)`
  &&& svg {
    fill: ${({ theme, $isMicOn }) =>
      $isMicOn
        ? theme.color.secondary.cherryBombRed[500].value
        : theme.color.additional.dark.value};
  }
`;

export const ChatContainer = ({
  chatLog,
  chatSize,
  headerText,
  isOpen,
  label,
  onChange,
  onChatChange,
  onChatClose,
  onChatResize,
  placeholder,
  request,
  userName,
}) => {
  const [isInputDisabled, setIsInputDisabled] = useState(false);
  const [isMicOn, setIsMicOn] = useState(false);
  const [transcript, setTranscript] = useState("");

  const messagesEndRef = useRef(null);
  const inputRef = useRef({ current: true });
  const sendTextTimeoutRef = useRef(null);

  const handleInputChange = (event) => {
    onChange(event);
    if (sendTextTimeoutRef.current) {
      clearTimeout(sendTextTimeoutRef.current);
      sendTextTimeoutRef.current = null;
    }
  };

  const handleKeyPress = (event) => {
    onChatChange(event);
  };

  const handleSendClick = (event) => {
    event.target.value = request;
    onChatChange(event, true);
  };

  const handleTypingChange = (isTyping) => {
    setIsInputDisabled(isTyping);
  };

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleMinimizeClick = () => {
    onChatResize("small");
    setTimeout(() => {
      if (isOpen && inputRef.current && !isInputDisabled) {
        inputRef.current.focus();
      }
    }, 0);
  };

  const handleMaximizeClick = () => {
    onChatResize("large");
    setTimeout(() => {
      if (isOpen && inputRef.current && !isInputDisabled) {
        inputRef.current.focus();
      }
    }, 0);
  };

  // Speech to text function
  const handleSpeechToText = () => {
    let finalResult = "";
    const recognition = new (window.SpeechRecognition ||
      window.webkitSpeechRecognition)();
    recognition.continuous = false;
    recognition.interimResults = true;

    recognition.onresult = (event) => {
      let interimTranscript = request + " ";
      for (let i = event.resultIndex; i < event.results.length; ++i) {
        interimTranscript += event.results[i][0].transcript;
      }
      setTranscript(interimTranscript);
      finalResult = interimTranscript;
    };

    recognition.onend = () => {
      setIsMicOn(false);
      recognition.stop();
      if (finalResult.trim() !== "") {
        sendTextTimeoutRef.current = setTimeout(() => {
          const inputText = { target: { value: finalResult } };
          onChatChange(inputText, true);
          setTranscript(""); // clears input on send
        }, 2000);
      }
    };

    recognition.onerror = (event) => {
      console.error("Speech recognition error detected:", event.error);
      setIsMicOn(false);
      recognition.stop();
    };
    recognition.start();
    setIsMicOn(true);
  };

  // sets transcript from speech to text to input when it changes
  useEffect(() => {
    const inputText = { target: { value: transcript } };
    onChange(inputText); // sends text to the input
  }, [transcript]);

  // this focuses on the input after the speech to text stops
  useEffect(() => {
    if (!isMicOn) {
      inputRef.current.focus();
    }
  }, [isMicOn]);

  // scrolls to bottom of the chat log whenever it changes
  useEffect(() => {
    scrollToBottom();
  }, [chatLog]);

  // focuses on the input when chat opens
  // timeout is needed for first iteration (so inputRef.current is populated)
  useEffect(() => {
    setTimeout(() => {
      if (isOpen && inputRef.current && !isInputDisabled) {
        inputRef.current.focus();
      }
    }, 0);
  }, [isOpen, inputRef, isInputDisabled]);

  return (
    <StyledChatWrapper>
      <StyledChatHeader>
        <StyledHeaderContent type={CONTENT_TYPES.BODY_2}>
          <span>{headerText}</span>
          <div>
            <AiIcon />
          </div>
        </StyledHeaderContent>
        <StyledHeaderContent>
          {chatSize === "large" && (
            <StyledHeaderIconButton
              icon={<StyledDisplayMinimizeIcon />}
              onClick={handleMinimizeClick}
              text="Minimize View"
            />
          )}
          {chatSize === "small" && (
            <StyledHeaderIconButton
              icon={<StyledDisplayMaximizeIcon />}
              onClick={handleMaximizeClick}
              text="Minimize View"
            />
          )}
          <StyledHeaderIconButton
            icon={<StyledIconArrowDropdown />}
            onClick={onChatClose}
            text="Close"
          />
        </StyledHeaderContent>
      </StyledChatHeader>
      {chatLog.length > 0 && (
        <StyledScrollingContainer>
          {chatLog.map((res, ind) => {
            if (res.type === "request") {
              return (
                <UserRequest
                  key={`${ind}`}
                  currentTime={res.currentTime}
                  text={res.text}
                  userName={userName}
                />
              );
            } else {
              return (
                <ChatResponse
                  key={`${ind}`}
                  chat={res}
                  currentTime={res.currentTime}
                  onTypingChange={handleTypingChange}
                />
              );
            }
          })}
          <div ref={messagesEndRef} />
        </StyledScrollingContainer>
      )}
      <StyledRequestContainer>
        <StyledInputNoLabel
          className="ChatInput"
          id="chat-cdk"
          isDisabled={isInputDisabled}
          label={label}
          maxLength={600}
          name="chat-cdk"
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
          placeholder={placeholder}
          ref={inputRef}
          rows={2}
          value={request}
        />
        <StyledMicrophoneIconButton
          icon={<MicIcon />}
          text="Microphone"
          onClick={handleSpeechToText}
          $isMicOn={isMicOn}
        />
        <StyledBlueIconButton
          icon={<IconSend />}
          text="Send Message"
          onClick={handleSendClick}
        />
      </StyledRequestContainer>
    </StyledChatWrapper>
  );
};
