import {
  AVATAR_SIZES,
  AVATAR_VARIANTS,
  Avatar,
  GlobalHeader,
  HeaderBlock,
  IconApps,
  IconButton,
  IconHelp,
  IconLogoDarkBg,
  IconNotification,
} from "cdk-radial";
import React from "react";
import styled from "styled-components";
import image from "./randomUser.png";
import { BACKGROUND_OPTIONS } from "../UnifyChatComponents";

const LogoBlock = styled(HeaderBlock)`
  cursor: pointer;
`;

const ButtonsBlock = styled(HeaderBlock)`
  gap: 8px;
`;

const StyledGlobalHeader = styled(GlobalHeader)`
  padding-right: 16px;
  padding-top: 8px;
  padding-bottom: 4px;
`;

export const AppHeader = ({
  onBackgroundChange,
  onChatVisibleChange,
  onIsNavVisibleChange,
}) => {
  const handleIsNavVisible = () => {
    onIsNavVisibleChange(false);
    onBackgroundChange(BACKGROUND_OPTIONS.UNIFY_HOME);
    onChatVisibleChange(false);
  };

  return (
    <StyledGlobalHeader isTopMenubar>
      <LogoBlock onClick={handleIsNavVisible} placement="left">
        <IconLogoDarkBg
          height="32"
          viewBox="0 0 300 77"
          width="120"
          style={{ paddingLeft: "16px" }}
        />
      </LogoBlock>
      <ButtonsBlock placement="right">
        <IconButton
          icon={<IconApps />}
          iconColor="primary"
          text="Apps"
          backgroundAppearance="dark"
        />
        <IconButton
          icon={<IconHelp />}
          iconColor="primary"
          text="Help"
          backgroundAppearance="dark"
        />
        <IconButton
          icon={<IconNotification />}
          text="Notifications"
          backgroundAppearance="dark"
        />
        <Avatar
          alt="User image"
          label="Alice"
          size={AVATAR_SIZES.SMALL}
          src={image}
          variant={AVATAR_VARIANTS.IMAGE}
        />
      </ButtonsBlock>
    </StyledGlobalHeader>
  );
};
