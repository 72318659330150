import { createIconWithPath } from "cdk-radial";

const AiIconPath = [
  {
    d: "M2.24667 6.75992C2.35142 6.86537 2.50274 6.86537 2.62495 6.7658C4.37078 5.39506 4.48718 5.4302 6.32034 6.75992C6.47163 6.86537 6.60548 6.88294 6.72186 6.76579C6.83243 6.6545 6.82662 6.50804 6.73932 6.38502C5.45321 4.48125 5.3601 4.39922 6.72186 2.64188C6.82081 2.51884 6.82078 2.36655 6.71603 2.26111C6.60546 2.14981 6.45417 2.14978 6.33779 2.24354C4.557 3.62597 4.44646 3.62012 2.6133 2.23181C2.49693 2.13811 2.34561 2.13811 2.23505 2.24941C2.12448 2.3607 2.13612 2.50129 2.21759 2.63013C3.51534 4.55738 3.56771 4.61009 2.22343 6.38502C2.1361 6.49632 2.1361 6.64863 2.24667 6.75992Z",
    fillRule: "evenodd",
  },
  {
    d: "M3.9803 14.1056C4.04418 14.1699 4.13645 14.1699 4.21098 14.1092C5.2756 13.2733 5.34658 13.2948 6.46445 14.1056C6.55671 14.1699 6.63834 14.1807 6.7093 14.1092C6.77673 14.0414 6.77318 13.952 6.71995 13.877C5.93567 12.7161 5.87889 12.6661 6.7093 11.5944C6.76964 11.5194 6.76963 11.4265 6.70575 11.3622C6.63832 11.2944 6.54606 11.2944 6.4751 11.3515C5.38916 12.1945 5.32175 12.191 4.20388 11.3444C4.13291 11.2872 4.04064 11.2872 3.97322 11.3551C3.90579 11.423 3.91289 11.5087 3.96257 11.5873C4.75395 12.7625 4.78588 12.7947 3.96613 13.877C3.91288 13.9449 3.91288 14.0378 3.9803 14.1056Z",
    fillRule: "evenodd",
  },
  {
    d: "M11.4608 5.57537C11.5247 5.63967 11.6169 5.63967 11.6914 5.57895C12.7561 4.74307 12.827 4.7645 13.9449 5.57537C14.0372 5.63967 14.1188 5.65039 14.1898 5.57895C14.2572 5.51108 14.2537 5.42177 14.2004 5.34675C13.4161 4.18582 13.3594 4.1358 14.1898 3.06416C14.2501 2.98913 14.2501 2.89626 14.1862 2.83196C14.1188 2.76409 14.0265 2.76408 13.9556 2.82125C12.8696 3.66427 12.8022 3.6607 11.6843 2.8141C11.6134 2.75696 11.5211 2.75696 11.4537 2.82483C11.3863 2.8927 11.3934 2.97843 11.443 3.057C12.2344 4.23224 12.2664 4.26439 11.4466 5.34675C11.3933 5.41462 11.3933 5.5075 11.4608 5.57537Z",
    fillRule: "evenodd",
  },
  {
    d: "M21.3311 23.5303C21.9392 24.1569 22.9814 24.1569 23.5605 23.5303C24.1398 22.8745 24.154 21.8981 23.5605 21.2861L9.79282 7.38381C9.18476 6.7718 8.14241 6.7718 7.56332 7.38381C6.96978 8.03958 6.96978 9.03048 7.56332 9.64255L21.3311 23.5303ZM21.8524 22.5247L12.9199 13.5189L13.6292 12.8194L22.5616 21.8253C22.8222 22.073 22.88 22.3645 22.6485 22.6267C22.3879 22.8453 22.113 22.8016 21.8524 22.5247Z",
    fillRule: "evenodd",
  },
];

export const AiIcon = createIconWithPath(AiIconPath);

<svg
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M2.24667 6.75992C2.35142 6.86537 2.50274 6.86537 2.62495 6.7658C4.37078 5.39506 4.48718 5.4302 6.32034 6.75992C6.47163 6.86537 6.60548 6.88294 6.72186 6.76579C6.83243 6.6545 6.82662 6.50804 6.73932 6.38502C5.45321 4.48125 5.3601 4.39922 6.72186 2.64188C6.82081 2.51884 6.82078 2.36655 6.71603 2.26111C6.60546 2.14981 6.45417 2.14978 6.33779 2.24354C4.557 3.62597 4.44646 3.62012 2.6133 2.23181C2.49693 2.13811 2.34561 2.13811 2.23505 2.24941C2.12448 2.3607 2.13612 2.50129 2.21759 2.63013C3.51534 4.55738 3.56771 4.61009 2.22343 6.38502C2.1361 6.49632 2.1361 6.64863 2.24667 6.75992Z"
    fill="white"
  />
  <path
    d="M3.9803 14.1056C4.04418 14.1699 4.13645 14.1699 4.21098 14.1092C5.2756 13.2733 5.34658 13.2948 6.46445 14.1056C6.55671 14.1699 6.63834 14.1807 6.7093 14.1092C6.77673 14.0414 6.77318 13.952 6.71995 13.877C5.93567 12.7161 5.87889 12.6661 6.7093 11.5944C6.76964 11.5194 6.76963 11.4265 6.70575 11.3622C6.63832 11.2944 6.54606 11.2944 6.4751 11.3515C5.38916 12.1945 5.32175 12.191 4.20388 11.3444C4.13291 11.2872 4.04064 11.2872 3.97322 11.3551C3.90579 11.423 3.91289 11.5087 3.96257 11.5873C4.75395 12.7625 4.78588 12.7947 3.96613 13.877C3.91288 13.9449 3.91288 14.0378 3.9803 14.1056Z"
    fill="white"
  />
  <path
    d="M11.4608 5.57537C11.5247 5.63967 11.6169 5.63967 11.6914 5.57895C12.7561 4.74307 12.827 4.7645 13.9449 5.57537C14.0372 5.63967 14.1188 5.65039 14.1898 5.57895C14.2572 5.51108 14.2537 5.42177 14.2004 5.34675C13.4161 4.18582 13.3594 4.1358 14.1898 3.06416C14.2501 2.98913 14.2501 2.89626 14.1862 2.83196C14.1188 2.76409 14.0265 2.76408 13.9556 2.82125C12.8696 3.66427 12.8022 3.6607 11.6843 2.8141C11.6134 2.75696 11.5211 2.75696 11.4537 2.82483C11.3863 2.8927 11.3934 2.97843 11.443 3.057C12.2344 4.23224 12.2664 4.26439 11.4466 5.34675C11.3933 5.41462 11.3933 5.5075 11.4608 5.57537Z"
    fill="white"
  />
  <path
    d="M21.3311 23.5303C21.9392 24.1569 22.9814 24.1569 23.5605 23.5303C24.1398 22.8745 24.154 21.8981 23.5605 21.2861L9.79282 7.38381C9.18476 6.7718 8.14241 6.7718 7.56332 7.38381C6.96978 8.03958 6.96978 9.03048 7.56332 9.64255L21.3311 23.5303ZM21.8524 22.5247L12.9199 13.5189L13.6292 12.8194L22.5616 21.8253C22.8222 22.073 22.88 22.3645 22.6485 22.6267C22.3879 22.8453 22.113 22.8016 21.8524 22.5247Z"
    fill="white"
  />
</svg>;
