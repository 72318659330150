import { IconMoreVert, transitionAnimation } from "cdk-radial";
import styled from "styled-components";

const TileContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 16px;
  padding: 16px;
  background-color: ${({ theme }) => theme.color.gray[50].value};
  border: 1px solid ${({ theme }) => theme.color.gray[100].value};
  border-radius: ${({ theme }) => theme.size.borderRadius.medium.value};
  width: 410px;
  svg {
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  &:hover {
    background-color: ${({ theme }) => theme.color.gray[100].value};
    border: 1px solid ${({ theme }) => theme.color.gray[200].value};
    svg {
      opacity: 1;
    }
  }
  ${transitionAnimation("background-color, border")};
`;

const Tile = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  height: 48px;
  width: 48px;
  background-color: ${({ $color }) => $color};
  border-radius: ${({ theme }) => theme.size.borderRadius.extraSmall.value};
  padding: 0;
  margin: 0;
`;

const TileInitials = styled.span`
  font-family: Montserrat;
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: -6%;
  color: white;
`;

const TileLabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const TileLabel = styled.span`
  font-family: Montserrat;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
`;

export const ISTile = ({ color, label, onTileClick, textInitials }) => {
  return (
    <TileContainer onClick={onTileClick}>
      <Tile $color={color}>
        <TileInitials>{textInitials}</TileInitials>
      </Tile>
      <TileLabelContainer>
        <TileLabel>{label}</TileLabel>
        <IconMoreVert />
      </TileLabelContainer>
    </TileContainer>
  );
};
